<template>
  <div id="landingpage">
    <Header :logo="'hide'" back-title="Shopping Cart" />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <c-box
          w="100%"
          mx="auto"
          mb="36px"
          pb="36px"
          pt="15px"
          borderBottom="1px solid #F2F2F2"
        >
          <c-grid mx="auto" w="700px" template-columns="repeat(3, 1fr)" gap="6">
            <c-box
              w="100%"
              fontWeight="bold"
              fontSize="18px"
              :color="
                orderId || programId || kuesionerId ? 'brand.900' : '#C4C4C4'
              "
            >
              <c-badge
                mx="2"
                fontSize="16px"
                :bg="
                  orderId || programId || kuesionerId ? 'brand.900' : '#C4C4C4'
                "
                color="white"
                borderRadius="50%"
                textAlign="center"
                lineHeight="32px"
                w="32px"
                h="32px"
              >
                1
              </c-badge>
              Pilih Program
              <c-icon
                ml="18px"
                name="check"
                :color="
                  orderId || programId || kuesionerId ? 'brand.900' : '#C4C4C4'
                "
              />
            </c-box>
            <c-box
              w="100%"
              fontWeight="bold"
              fontSize="18px"
              :color="programId || kuesionerId ? 'brand.900' : '#C4C4C4'"
            >
              <c-badge
                mx="2"
                fontSize="16px"
                :bg="programId || kuesionerId ? 'brand.900' : '#C4C4C4'"
                color="white"
                borderRadius="50%"
                textAlign="center"
                lineHeight="32px"
                w="32px"
                h="32px"
              >
                2
              </c-badge>
              Pilih Ahli Gizi
              <c-icon
                ml="18px"
                name="check"
                :color="programId || kuesionerId ? 'brand.900' : '#C4C4C4'"
              />
            </c-box>
            <c-box
              w="100%"
              fontWeight="bold"
              fontSize="18px"
              :color="kuesionerId ? 'brand.900' : '#C4C4C4'"
            >
              <c-badge
                mx="2"
                fontSize="16px"
                :bg="kuesionerId ? 'brand.900' : '#C4C4C4'"
                color="white"
                borderRadius="50%"
                textAlign="center"
                lineHeight="32px"
                w="32px"
                h="32px"
              >
                3
              </c-badge>
              Isi Kuesioner
              <c-icon ml="18px" name="check" :color="kuesionerId ? 'brand.900' : '#C4C4C4'" />
            </c-box>
          </c-grid>
        </c-box>


        <template v-if="kuesionerId != null">
          <c-text
          textAlign="center"
          color="brand.900"
          fontWeight="bold"
          fontSize="18px"
          lineHeight="27px"
          mb="14px"
        >
          Isi Kuisioner
        </c-text>
        <c-heading
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          marginBottom="40px"
          textAlign="center"
          lineHeight="54px"
        >
          Submit Berhasil!
        </c-heading>
        </template>
        <template v-else>
        <c-text
          textAlign="center"
          color="brand.900"
          fontWeight="bold"
          fontSize="18px"
          lineHeight="27px"
          mb="14px"
          v-text="successMessages.title"
        />
        <c-heading
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          marginBottom="40px"
          textAlign="center"
          lineHeight="54px"
        >
          {{ successMessages.body }}
        </c-heading>
        </template>


        <c-flex marginTop="100px" justifyContent="center">
          <c-box w="100%" :max-w="['173px', '641px']" textAlign="center">
            <c-image
              :src="require('@/assets/paid.png')"
              mx="auto"
              marginBottom="30px"
            />
            <c-text
              v-if="kuesionerId != null"
              marginBottom="20px"
              color="gray.900"
              :fontSize="['14px', '24px']"
              :lineHeight="['21px', '36px']"
              fontFamily="Roboto"
            >
              Kuesioner anda telah berhasil di-submit
            </c-text>
            <c-text
              v-else
              marginBottom="20px"
              color="gray.900"
              :fontSize="['14px', '24px']"
              :lineHeight="['21px', '36px']"
              fontFamily="Roboto"
              v-html="successMessages.description"
            />
            <c-button
              v-if="orderId"
              as="router-link"
              :to="{ name: 'client.nutritionists' }"
              fontWeight="700"
              right-icon="arrow-forward"
              color="brand.900"
              variant="outline"
              borderColor="brand.900"
              borderRadius="200px"
              py="10px"
              px="30px"
            >
              Pilih Ahli Gizi
            </c-button>
            <c-button
              v-if="programId"
              as="router-link"
              :to="{
                name: 'client.kuisioner',
                params: { programId: programId },
              }"
              fontWeight="700"
              right-icon="arrow-forward"
              color="brand.900"
              variant="outline"
              borderColor="brand.900"
              borderRadius="200px"
              py="10px"
              px="30px"
            >
              Isi Kuesioner
            </c-button>
            <c-button
              v-if="kuesionerId"
              as="router-link"
              :to="{
                name: 'content-challenge.coaching-course',
                params: { programId: programId },
              }"
              fontWeight="700"
              right-icon="arrow-forward"
              color="brand.900"
              variant="outline"
              borderColor="brand.900"
              borderRadius="200px"
              py="10px"
              px="30px"
            >
              Mulai Dietela
            </c-button>
          </c-box>
        </c-flex>
      </c-box>
    </c-box>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "SuccessPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // programId: null,
    };
  },
  computed: {
    ...mapGetters({
      successMessages: "clients/successPayload",
    }),
    axios() {
      return this.$store.getters.axios;
    },
    orderId() {
      return this.$route.query.order_id;
    },
    programId() {
      return this.$route.query.program_id;
    },
    kuesionerId() {
      return this.$route.query.kuesioner_id;
    },
  },
  async mounted() {
    // if (this.orderId) {
    //   // Get Transaction
    //   this.axios
    //     .get(`/v1/clients/orders/${this.orderId}`)
    //     .then((r) => r.data.data)
    //     .then((order) => {
    //       this.programId = order.programId;
    //     });
    // }
  },
};
</script>

<style></style>
